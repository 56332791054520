import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from '../types';

export const BitcoinAltIcon: FC<IconProps> = ({ width = 40, height = 40 }) => {
  return (
    <SvgIcon width="180" height="124" viewBox="0 0 180 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M131.796 62.3337C131.796 85.3458 113.227 104.001 90.3226 104.001C67.4177 104.001 48.8496 85.3458 48.8496 62.3337C48.8496 39.3216 67.4177 20.6667 90.3226 20.6667C113.227 20.6667 131.796 39.3216 131.796 62.3337ZM100.286 46.853C106.055 48.8433 110.275 51.8236 109.448 57.37C108.846 61.4315 106.597 63.3958 103.608 64.0835C107.708 66.2198 109.106 70.274 107.807 75.1763C105.342 82.2311 99.4819 82.8251 91.6874 81.3506L89.7948 88.9342L85.2259 87.7932L87.0925 80.3111C85.908 80.0167 84.6974 79.7015 83.4504 79.3654L81.576 86.8839L77.0123 85.7429L78.9023 78.1436L69.6994 75.8198L71.9696 70.5834C71.9696 70.5834 75.3383 71.477 75.2915 71.4119C76.5853 71.7323 77.1607 70.8882 77.3872 70.3281L82.5158 49.7447C82.5731 48.773 82.2372 47.5485 80.3862 47.0848C80.4565 47.0353 77.0669 46.259 77.0669 46.259L78.2827 41.3743L87.4986 43.659L89.373 36.1457L93.9393 37.2868L92.104 44.6516C93.3327 44.9303 94.5667 45.2143 95.7669 45.5139L97.5892 38.196L102.158 39.337L100.286 46.853ZM89.3552 59.3862C92.4675 60.2152 99.2399 62.0191 100.42 57.2894C101.625 52.4503 95.0449 50.9888 91.8234 50.2732L91.8232 50.2732L91.8231 50.2731C91.4581 50.1921 91.1363 50.1206 90.8738 50.0548L88.5984 59.1885C88.8152 59.2424 89.0698 59.3102 89.3552 59.3862ZM85.8278 74.0918C89.5554 75.0783 97.7082 77.2358 99.0054 72.0241C100.333 66.6953 92.4287 64.9204 88.5718 64.0543C88.1409 63.9576 87.7605 63.8722 87.4491 63.7944L84.9395 73.8608C85.1944 73.9242 85.4931 74.0032 85.8273 74.0917L85.8278 74.0918Z" fill="#24292D"/>
    </SvgIcon>


  );
};