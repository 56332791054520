import { FC } from 'react';

import { IconProps } from '../types';

export const QiwiIcon: FC<IconProps> = ({ width = 48, height = 48 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 193 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M96.5004 106.637C121.098 106.637 141.039 86.6514 141.039 61.9974C141.039 37.3434 121.098 17.3574 96.5004 17.3574C71.9025 17.3574 51.9619 37.3434 51.9619 61.9974C51.9619 86.6514 71.9025 106.637 96.5004 106.637Z"
        fill="#FF8C00"
      />
      <path
        d="M114.844 71.6151C114.986 72.7339 114.669 73.1663 114.316 73.1663C113.962 73.1663 113.467 72.7339 112.938 71.869C112.409 71.0041 112.194 70.0276 112.478 69.5254C112.654 69.1989 113.043 69.0538 113.503 69.2352C114.421 69.5979 114.775 71.0013 114.844 71.6151ZM109.898 74.0703C110.992 75.0077 111.312 76.0874 110.744 76.8826C110.566 77.0913 110.343 77.2577 110.093 77.3696C109.843 77.4816 109.571 77.5363 109.297 77.5299C108.686 77.5359 108.095 77.3175 107.635 76.9161C106.647 76.0512 106.363 74.6087 106.997 73.8164C107.133 73.6483 107.306 73.5149 107.503 73.4271C107.7 73.3393 107.915 73.2996 108.13 73.3114C108.695 73.3114 109.333 73.5625 109.898 74.0675V74.0703ZM71.4473 58.9178C71.4473 45.2105 82.3314 34.1035 95.7597 34.1035C109.191 34.1035 120.075 45.2133 120.075 58.9178C120.075 63.3912 118.893 67.7848 116.648 71.6513C116.576 71.7602 116.398 71.7239 116.364 71.5788C115.518 65.4826 111.877 62.1291 106.577 61.1191C106.115 61.0465 106.045 60.7564 106.647 60.6866C108.272 60.5416 110.569 60.5778 111.768 60.7955C111.842 60.1601 111.878 59.5211 111.877 58.8815C111.877 49.828 104.667 42.4707 95.7959 42.4707C86.9272 42.4707 79.7175 49.828 79.7175 58.8815C79.7175 67.9379 86.9272 75.2951 95.7959 75.2951H96.5391C96.2867 73.8306 96.1795 72.3446 96.219 70.859C96.2552 69.849 96.4695 69.7039 96.8926 70.4963C99.1196 74.4302 102.299 77.9651 108.52 79.3685C113.608 80.5263 118.697 81.86 124.175 88.9661C124.668 89.5799 123.927 90.2272 123.362 89.7222C117.778 84.6723 112.69 83.0122 108.061 83.0122C102.864 83.0513 99.3311 83.7376 95.7597 83.7376C82.3314 83.7376 71.4473 72.6223 71.4473 58.915V58.9178Z"
        fill="white"
      />
    </svg>
  );
};
