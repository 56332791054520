import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from '../types';

export const BitcoinIcon: FC<IconProps> = ({ width = 40, height = 40 }) => {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2415_154471)">
        <path d="M39.401 24.8382C36.7295 35.5525 25.8765 42.073 15.1599 39.4012C4.44759 36.73 -2.07366 25.8776 0.599095 15.1639C3.26935 4.44845 14.1223 -2.07273 24.8358 0.59849C35.5518 3.26972 42.0725 14.1233 39.401 24.8382Z" fill="#F7931A"/>
        <path d="M27.0716 18.0403C27.386 15.9388 25.7858 14.8091 23.5976 14.0555L24.3074 11.2087L22.5744 10.7768L21.8834 13.5486C21.4278 13.4351 20.9598 13.328 20.4949 13.2219L21.1908 10.4319L19.4588 10L18.7485 12.8458C18.3714 12.7599 18.0012 12.675 17.6418 12.5857L17.6438 12.5768L15.2538 11.9801L14.7928 13.8309C14.7928 13.8309 16.0786 14.1255 16.0515 14.1438C16.7534 14.319 16.8802 14.7835 16.859 15.1517L16.0505 18.3948C16.0989 18.4071 16.1616 18.4248 16.2306 18.4525C16.1729 18.4381 16.1112 18.4224 16.0475 18.4071L14.9142 22.9502C14.8283 23.1634 14.6106 23.4832 14.12 23.3618C14.1373 23.3869 12.8603 23.0474 12.8603 23.0474L12 25.031L14.2552 25.5931C14.6748 25.6982 15.086 25.8083 15.4908 25.9119L14.7736 28.7913L16.5046 29.2232L17.2149 26.3744C17.6877 26.5027 18.1468 26.6212 18.5959 26.7327L17.8881 29.5681L19.6212 30L20.3384 27.1261C23.2935 27.6853 25.5157 27.4597 26.4511 24.7871C27.2048 22.6353 26.4136 21.394 24.8587 20.5846C25.9911 20.3235 26.844 19.5787 27.0716 18.0403ZM23.1119 23.5923C22.5764 25.7442 18.9528 24.5809 17.7781 24.2892L18.7297 20.4746C19.9045 20.7677 23.6716 21.3481 23.1119 23.5923ZM23.6479 18.0093C23.1593 19.9667 20.1434 18.9722 19.1651 18.7284L20.0279 15.2686C21.0062 15.5124 24.1568 15.9675 23.6479 18.0093Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_2415_154471">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </SvgIcon>


  );
};