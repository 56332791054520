import { SvgIcon } from "@mui/material";
import { FC } from 'react';

import { IconProps } from '../types';

export const MirIcon: FC<IconProps> = ({ width = 48, height = 48 }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 193 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.9473 39.6951C31.4063 39.6864 35.7423 39.295 37.5789 45.5067C38.8161 49.691 40.7868 56.545 43.4912 66.0688H44.5925C47.4927 56.0282 49.4849 49.1741 50.5693 45.5067C52.4251 39.2303 57.0645 39.6952 58.9202 39.6952L73.238 39.6953V84.3274H58.645V58.0249H57.6664L49.5315 84.3274H38.5521L30.4173 58.0054H29.4387V84.3274H14.8457V39.6953L29.9473 39.6951ZM94.1932 39.6953V66.0173H95.3574L105.253 44.3729C107.174 40.0656 111.268 39.6953 111.268 39.6953H125.389V84.3275H110.491V58.0054H109.327L99.6259 79.6499C97.7051 83.9377 93.4171 84.3275 93.4171 84.3275H79.2954V39.6953H94.1932ZM176.883 60.9048C174.804 66.806 168.278 71.0323 161.053 71.0323H145.43V84.3275H131.263V60.9048H176.883Z"
        fill="#0F754E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161.736 39.6953H130.52C131.262 49.632 139.802 58.1384 148.642 58.1384H177.866C179.553 49.8799 173.747 39.6953 161.736 39.6953Z"
        fill="url(#paint0_linear_1837_95878)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1837_95878"
          x1="178.153"
          y1="51.2968"
          x2="130.52"
          y2="51.2968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F5CD7" />
          <stop offset="1" stopColor="#02AEFF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
