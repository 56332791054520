import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from '../types';

export const GpayIcon: FC<IconProps> = ({ width = 40, height = 40 }) => {
  return (
  <SvgIcon width="130" height="54" viewBox="0 0 130 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M60.0121 42.4163V26.808H68.0307C71.3165 26.808 74.0898 25.7019 76.3507 23.5204L76.8933 22.9674C81.0232 18.4508 80.7519 11.4148 76.3507 7.23622C74.1501 5.02402 71.1356 3.82575 68.0307 3.8872H55.1587V42.4163H60.0121ZM60.0126 22.0766V8.61912H68.1531C69.9018 8.61912 71.5601 9.29507 72.7962 10.5241C75.4193 13.105 75.4796 17.4065 72.947 20.0795C71.7108 21.4007 69.9621 22.1381 68.1531 22.0766H60.0126ZM99.5332 18.1132C97.4532 16.1775 94.6195 15.1943 91.0323 15.1943C86.42 15.1943 82.9534 16.9149 80.6623 20.3253L84.9429 23.0599C86.5105 20.7248 88.6508 19.5572 91.3638 19.5572C93.0821 19.5572 94.7401 20.2024 96.0364 21.37C97.3024 22.4761 98.0259 24.0738 98.0259 25.7637V26.9005C96.1569 25.8558 93.8056 25.3028 90.9117 25.3028C87.5354 25.3028 84.8223 26.1016 82.8026 27.7301C80.7829 29.3585 79.758 31.5092 79.758 34.2437C79.6977 36.7325 80.7528 39.0983 82.6218 40.696C84.5209 42.4166 86.9325 43.2769 89.7662 43.2769C93.1123 43.2769 95.765 41.7714 97.7848 38.7603H97.9958V42.4166H102.638V26.1631C102.638 22.7526 101.613 20.0488 99.5332 18.1132ZM86.3606 37.562C85.3656 36.8246 84.7626 35.6263 84.7626 34.3359C84.7626 32.8918 85.4259 31.6936 86.7224 30.7411C88.049 29.7886 89.7072 29.297 91.667 29.297C94.3805 29.2663 96.491 29.8808 97.9985 31.1098C97.9985 33.1991 97.1844 35.0118 95.5865 36.5481C94.1393 38.0229 92.1795 38.8525 90.1293 38.8525C88.7726 38.8832 87.446 38.4223 86.3606 37.562ZM113.068 54L129.286 16.0547H124.01L116.504 34.9812H116.414L108.727 16.0547H103.451L114.093 40.7268L108.064 54H113.068Z" fill="#24292D"/>
    <path d="M43.2566 23.4286C43.2566 21.9231 43.136 20.4175 42.8948 18.9427H22.4263V27.4536H34.1527C33.6704 30.1881 32.1029 32.6461 29.8118 34.1823V39.7128H36.8055C40.9052 35.8722 43.2566 30.1881 43.2566 23.4286Z" fill="#363E45"/>
    <path d="M22.428 45.0591C28.2762 45.0591 33.22 43.0927 36.8073 39.7129L29.8136 34.1824C27.8541 35.5343 25.3521 36.3025 22.428 36.3025C16.7607 36.3025 11.9676 32.4004 10.2494 27.1772H3.04468V32.892C6.72239 40.3582 14.2285 45.0591 22.428 45.0591Z" fill="#363E45"/>
    <path d="M10.2493 27.1771C9.34478 24.4426 9.34478 21.4623 10.2493 18.697V13.0129H3.04344C-0.062006 19.2501 -0.062006 26.6241 3.04344 32.8612L10.2493 27.1771Z" fill="#3F4950"/>
    <path d="M22.428 9.57166C25.533 9.51021 28.5173 10.7085 30.7481 12.89L36.958 6.56062C33.009 2.81218 27.824 0.753608 22.428 0.815058C14.2285 0.815058 6.72239 5.5467 3.04468 13.0129L10.2494 18.7277C11.9676 13.4737 16.7607 9.57166 22.428 9.57166Z" fill="#2D3439"/>
  </SvgIcon>

  );
};