import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from '../types';

export const ApplePayIcon: FC<IconProps> = ({ width = 40, height = 40 }) => {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2415_154467)">
        <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#191919"/>
        <path d="M11.8238 16.4181C11.5144 16.7897 11.0193 17.0828 10.5243 17.0409C10.4624 16.5385 10.7048 16.0047 10.9884 15.675C11.2978 15.293 11.8393 15.021 12.2776 15C12.3292 15.5233 12.1281 16.0361 11.8238 16.4181ZM12.2724 17.1404C11.5556 17.0985 10.942 17.5537 10.6016 17.5537C10.2561 17.5537 9.73525 17.1612 9.168 17.1717C8.4306 17.1822 7.7447 17.6061 7.36825 18.2812C6.59475 19.6313 7.16715 21.6303 7.9149 22.7293C8.28105 23.2736 8.71935 23.8701 9.29695 23.8492C9.84355 23.8282 10.0602 23.4881 10.7202 23.4881C11.3855 23.4881 11.5763 23.8492 12.1538 23.8387C12.752 23.8282 13.1285 23.2945 13.4946 22.7502C13.9123 22.1328 14.0825 21.5309 14.0928 21.4995C14.0825 21.4891 12.9377 21.0442 12.9274 19.7045C12.9171 18.5847 13.8298 18.0509 13.8711 18.0195C13.3554 17.245 12.5509 17.1613 12.2724 17.1404ZM16.4134 15.6228V23.7812H17.6613V20.9919H19.3889C20.9669 20.9919 22.0756 19.8929 22.0756 18.3021C22.0756 16.7112 20.9875 15.6228 19.4301 15.6228H16.4134ZM17.6613 16.6903H19.1001C20.183 16.6903 20.8019 17.2764 20.8019 18.3074C20.8019 19.3383 20.183 19.9296 19.0949 19.9296H17.6613V16.6903ZM24.3549 23.844C25.1388 23.844 25.8659 23.441 26.1959 22.8025H26.2217V23.7812H27.3768V19.7202C27.3768 18.5428 26.4486 17.784 25.0201 17.784C23.6949 17.784 22.7151 18.5533 22.679 19.6103H23.8031C23.896 19.108 24.3549 18.7783 24.9841 18.7783C25.7473 18.7783 26.1752 19.1394 26.1752 19.804V20.254L24.6179 20.3482C23.1689 20.4372 22.385 21.039 22.385 22.0856C22.385 23.1427 23.1946 23.844 24.3549 23.844ZM24.6901 22.8759C24.0249 22.8759 23.602 22.5514 23.602 22.0543C23.602 21.5414 24.0094 21.2431 24.7881 21.196L26.1752 21.107V21.5676C26.1752 22.3316 25.5358 22.8759 24.6901 22.8759ZM28.9187 26C30.1357 26 30.7081 25.529 31.2083 24.1004L33.4 17.8625H32.1314L30.6617 22.6822H30.636L29.1663 17.8625H27.8615L29.9758 23.8021L29.8624 24.1632C29.6716 24.7755 29.3622 25.011 28.8104 25.011C28.7125 25.011 28.5217 25.0005 28.4443 24.99V25.9686C28.5165 25.9895 28.8259 26 28.9187 26Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_2415_154467">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </SvgIcon>


  );
};