import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from '../types';

export const MastercardIcon: FC<IconProps> = ({ width = 40, height = 40 }) => {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2415_154477)">
        <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#1A1A1A"/>
        <path d="M23.1693 25.1112H17.4551V14.8907H23.1693V25.1112Z" fill="#FF5F00"/>
        <path d="M17.8221 20C17.8221 17.9268 18.7974 16.0799 20.3163 14.8897C19.2056 14.0194 17.8038 13.5 16.2803 13.5C12.6736 13.5 9.75 16.4101 9.75 20C9.75 23.5899 12.6736 26.5 16.2803 26.5C17.8038 26.5 19.2056 25.9806 20.3163 25.1103C18.7974 23.9201 17.8221 22.0732 17.8221 20Z" fill="#EB001B"/>
        <path d="M30.8753 20C30.8753 23.5899 27.9517 26.5 24.345 26.5C22.8215 26.5 21.4197 25.9806 20.3086 25.1103C21.8279 23.9201 22.8033 22.0732 22.8033 20C22.8033 17.9268 21.8279 16.0799 20.3086 14.8897C21.4197 14.0194 22.8215 13.5 24.345 13.5C27.9517 13.5 30.8753 16.4101 30.8753 20Z" fill="#F79E1B"/>
      </g>
      <defs>
        <clipPath id="clip0_2415_154477">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </SvgIcon>

  );
};